/* Import Roboto */
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

:root {
  --plum: #5a2536;
  --purple: #3a253c;
  --lilac: #765e6d;
  --wine: #4d1324;

  --extragray: #eaeced;
  --lightestgray: #f4f4f5;
  --lightgray: #e8e9ea;
  --dovegray: #dddedf;
  --midgray: #adadad;
  --darkgray: #9a9a9a;
  --greengray: #454e4a;
  --darkestgray: #231f20;
  --redgray: #170000;

  --brightred: #d41320;
  --midred: #7c2014;
  --darkred: #610b00;

  --black: #000000;
  --white: #ffffff;

  /* Define RGB components */
  --plum-rgb: 90, 37, 54;
  --purple-rgb: 58, 37, 60;
  --lilac-rgb: 118, 94, 109;
  --wine-rgb: 77, 19, 36;

  --extragray-rgb: 234, 236, 237;
  --lightestgray-rgb: 244, 244, 245;
  --lightgray-rgb: 232, 233, 234;
  --dovegray-rgb: 221, 222, 223;
  --midgray-rgb: 173, 173, 173;
  --darkgray-rgb: 154, 154, 154;
  --greengray-rgb: 69, 78, 74;
  --darkestgray-rgb: 35, 31, 32;
  --redgray-rgb: 23, 0, 0;

  --brightred-rgb: 212, 19, 32;
  --midred-rgb: 124, 32, 20;
  --darkred-rgb: 97, 11, 0;

  --black-rgb: 0, 0, 0;
  --white-rgb: 255, 255, 255;

  /* Set a default opacity */
  --default-opacity: 1;
}

/* ===============================
   Universal Styles
   =============================== */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

/* ===============================
   Header Styles
   =============================== */

.collapse-btn {
  position: fixed;
  top: 70px;
  right: 15px;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 999;
  font-size: 50px !important;
  color: var(--text-color);
  font-weight: bolder;
  user-select: none;
  -webkit-user-drag: none;
  transition: top 0.5s ease-in-out;
}

.collapse-btn:focus {
  outline: none;
}

.header-container {
  position: relative;
}

header {
  text-align: center;
  font-size: smaller;
  font-weight: 300;
  transition: max-height 0.7s ease-in-out, padding 0.7s ease-in-out;
  position: fixed;
  z-index: 10;
  margin-bottom: 0;
  max-height: 100px;
  left: 50%;
  width: 110%;
  height: 120px;
  overflow: hidden;
  user-select: none;
  -webkit-user-drag: none;
  transform: translateX(-50%); /* Center the header */
  z-index: 999;
}

header.collapsed {
  max-height: 0;
  padding: 0;
  transition: max-height 0.7s ease-in-out, padding 0.7s ease-in-out;
}

/* ===============================
      Header Hover Effect
      =============================== */

header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(120, 128, 143, 0.2),
    /* Lightened version of the navbar color */ rgba(115, 126, 150, 0)
      /* Fully transparent */
  );
  z-index: -1; /* Ensure the gradient is behind the content */
}

header h1 {
  display: flex;
  max-width: 12vw;
  flex-direction: column;
  font-weight: 100;
  font-size: 30px;
  margin-left: 8vw;
  margin-top: 10px;
  text-align: left;
  color: #9ab0d1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1); /* Enhance readability */
  z-index: 10;
}

header h1:hover {
  cursor: pointer;
  color: #8a657c;
}

/* ===============================
      Navigation Menu Styles
      =============================== */
body {
  margin: 0;
  overflow: hidden;
}
canvas {
  display: block;
}

@media (min-width: 620px) {
  nav button {
    white-space: nowrap;
  }
}

#navbar {
  text-align: center;
  padding: 20px;
  z-index: 999;
  position: fixed;
  top: 0; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  white-space: normal;
  width: 50%; /* Ensure it spans the full width */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav {
  display: flex; /* Allow for wrapping */
  justify-content: space-evenly; /* Distribute buttons evenly */
  align-items: center; /* Ensure buttons are aligned */
  white-space: normal; /* Allow wrapping */
}

nav button {
  color: #9ab0d1;
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-weight: bolder;
  font-size: inherit; /* Inherit from the container */
  z-index: 999;
  border-bottom: 3px solid transparent;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1); /* Enhance readability */
  margin: 1px 14px; /* Add horizontal spacing */
  transition: border-bottom 0.2s ease;
  display: inline-block;
}

nav button:hover {
  color: #6b8fbf;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 1); /* Create a subtle bold effect */
  border-bottom: 3px solid black;
  transition: border-bottom 0.2s ease;
}

.activeNav {
  border-bottom: 3px solid black;
}

@media (max-width: 620px) {
  nav {
    padding: 10px; /* Adjust padding if needed */
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }
}

/* ===============================
   Social Links Style
   =============================== */
.social-links {
  position: fixed;
  top: 10px;
  right: 8vw;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.social-links a {
  font-family: "Roboto", sans-serif;
  color: #9ab0d1;
  font-weight: bolder;
  font-size: 14px !important;
  opacity: 1;
  text-decoration: none;
  padding-left: 5px;
  font-size: small;
  margin-bottom: 6px; /* Space between links */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 1); /* Enhance readability */
}

.social-links a:hover {
  color: #6b8fbf;
  opacity: 1;
}

/* ===============================
   Minimum Page Size
   =============================== */
body {
  position: relative;
  min-width: 300px; /* Prevent body from shrinking too much */
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: scroll; /* Ensure vertical scrolling */
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px; /* Adjust the height of the shadow */
  background: linear-gradient(
    to bottom,
    rgba(36, 36, 36, 0.7) 0%,
    /* Start at 50% opacity */ rgba(36, 36, 36, 0.07) 80%,
    /* Almost transparent near the bottom */ rgba(36, 36, 36, 0) 100%
      /* Fully transparent at the bottom */
  );
  z-index: 2; /* Ensure it's above other elements */
  pointer-events: none;
}

/* ===============================
   Text Highlighting Prevention
   =============================== */
.noselect {
  user-select: none;
  -webkit-user-drag: none;
}

/* ===============================
   Text Highlighting Color
   =============================== */
/* Works on common browsers */
::selection {
  background-color: #374454;
  color: #fff;
}

/* Mozilla-based browsers */
::-moz-selection {
  background-color: #374454;
  color: #fff;
}

/* Internet Explorer and Edge */
::-ms-selection {
  background-color: #374454;
  color: #fff;
}

/* Opera */
::-o-selection {
  background-color: #374454;
  color: #fff;
}

/* WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-selection {
  background-color: #374454;
  color: #fff;
}

.topButton {
  position: fixed;
  bottom: 10px;
  right: 15px;
  background: transparent;
  font-size: 18px;
  font-weight: bolder;
  text-decoration: none;
  color: #374454;
  border: none;
  z-index: 10;
  user-select: none;
  -webkit-user-drag: none;
  transition: transform 0.1s ease-in-out;
}

.topButton:hover {
  color: #000;
}

.topButton:active {
  transform: scale(1.2);
}

#copyright {
  position: relative; /* Position it relative to the nearest positioned ancestor (body) */
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
  z-index: 9999999; /* Ensure it appears on top of other content */
  padding: 15px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-top: 1px solid var(--copyright-border);
  background: var(--background-color); /* No background color */
  pointer-events: none;
}

#copyright::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

#copyright p {
  color: var(--text-color);
}
