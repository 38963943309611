:root {
  --background-color: white;
  --text-color: black;
  --primary-color: #adb4b9ad;
  --secondary-color: #4e4f50b7;
  --placeholder-color: #ffffff;
  --link-color: #1f2432;
  --submit-color: #979ca1b4;
  --border-color: #333241;
  --copyright-border: #33324178;
}

/* Define dark theme */
[data-theme="dark"] {
  /* main:#222426
   container: background-color: rgba(64, 65, 77, 0.479);
   overlay: rgba(0, 0, 0, 0.238)*/
  --background-color: #222426;
  --text-color: #fff;
  --primary-color: rgba(64, 65, 77, 0.479);
  --secondary-color: rgba(0, 0, 0, 0.238);
  --placeholder-color: #898686;
  --link-color: #c4d4ff;
  --submit-color: #808080;
  --border-color: #333241;
  --copyright-border: #333241;
}

#dark-mode-toggle {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  color: var(--text-color);
  display: flex; /* Center the content */
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: pointer;
  pointer-events: auto;
  user-select: none;
  transition: transform 1s ease;
}

.light-mode-icon,
.dark-mode-icon {
  font-size: 40px;
  transition: transform 0.2s ease;
  transform-origin: center; /* Make sure scaling happens from the center */
}

.light-mode-icon:hover,
.dark-mode-icon:hover {
  transform: scale(1.15); /* Optional: add scaling effect */
}
/* Add press effect on click */
#dark-mode-button:active {
  transform: scale(1); /* Shrinks the button on click to simulate pressing */
}

/* Press (active) effect on the icon */
#dark-mode-button:active .light-mode-icon,
#dark-mode-button:active .dark-mode-icon {
  transform: scale(1); /* Shrinks the icon too when pressed */
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.spinner {
  font-size: 50px;
  color: white;
}

.audioPopout {
  position: fixed;
  display: flex;
  flex-wrap: column;
  cursor: pointer;
  width: 50px;
  margin-bottom: 5px;
  top: 200px;
  right: 15px;
  font-size: 10px;
  color: var(--text-color);
  user-select: none;
  z-index: 99;
}
.audioIcon {
  font-size: 30px;
}

.audioSection {
  position: absolute;
  margin-bottom: 5px;
  top: 0;
  right: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 300px;
  font-size: 10px;
  color: var(--text-color);
  background-color: #22272e7c;
  z-index: 9;
}

.material-icons.back,
.material-icons.skip,
.material-icons.play,
.material-icons.pause {
  font-size: 20px;
  color: var(--text-color);
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
}

.rms-visual {
  width: 100%;
  height: 80px;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 999999999;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  background-color: #1a1a1a; /* Dark background for contrast */
  border-radius: 5px; /* Optional: rounds the edges */
  border: 1px solid #333; /* Optional: adds a subtle border */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Optional: adds shadow */
}
